<template>
  <div class="drawProduct">
    <van-nav-bar left-arrow :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
      class="nav">
    </van-nav-bar>
    <div class="drawProduct-box">
      <van-image class="productShareImage" :src=shareImage></van-image>
      <van-image class="successImage" :src="require('../../assets/success.png')" width="35px"></van-image>
      <div class="result_txt">领取成功</div>
      <div class="info">领取成功，稍后即可查看...</div>
    </div>
    <div class="drawProduct-button">
      <button class="go_back" @click="goBack">返回</button>
      <button class="get_order" @click="toOrder">查看订单</button>
    </div>
  </div>

</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
export default {
  name: "drawProduct",
  components: { Certification, TopBar },
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const showDetail = ref(false);
    const showImage = ref(false)
    let productImgs = ref("");

    const state = reactive({
      listData: [],
      shareImage:""
    });
    state.shareImage = $router.currentRoute.value.query.shareImage
    console.log('shareImage:'+state.shareImage)
    // let productDetail = JSON.parse($router.currentRoute.value.query.productDetail)
    // console.log(productDetail)
    // proxy.$http.get("/api/wenwu-user/order/collection/certificate?id=" + productDetail.id).then(res => {
    //   state.listData = res.data.data;
    // })
    onMounted(async () => {
    });
    const goBack = (() => {
      $router.back(-1)
    })
    const toOrder = (() => {
      $router.push("/order")
    })

    return {
      goBack,
      ...toRefs(state),
      toOrder
      // productDetail,
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


